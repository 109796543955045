<!-- (c) 2021 Cofense Inc. -->
<template>
  <portal :to="portalName">
    <koi-modal
      v-if="isOpen"
      id="leave-app-modal"
      :on-dismiss="onCancel"
      intent="error"
    >
      <template #title>{{ title }}</template>
      <template #content>
        <slot name="content" :url="url" />
      </template>
      <template #control-group-left>
        <koi-button
          intent="tertiary"
          data-qa="cancel"
          @click.native="onCancel"
        >
          {{ cancelText }}
        </koi-button>
      </template>
      <template #control-group-right>
        <koi-button
          intent="danger"
          data-qa="continue"
          @click.native="onContinue"
        >
          {{ continueText }}
        </koi-button>
      </template>
    </koi-modal>
  </portal>
</template>

<script lang="ts">
  import Vue, { PropType } from 'vue';

  export type IsExternalSiteCallback = (link: HTMLAnchorElement) => boolean;

  export default Vue.extend({
    name: 'leave-app-modal',

    props: {
      cancelText: {
        required: true,
        type: String as PropType<string>,
      },
      continueText: {
        required: true,
        type: String as PropType<string>,
      },
      isExternalSite: {
        default: (() => false) as Function,
        type: Function as PropType<IsExternalSiteCallback>,
      },
      portalName: {
        required: true,
        type: String as PropType<string>,
      },
      title: {
        required: true,
        type: String as PropType<string>,
      },
    },

    data: () => ({
      isOpen: false,
      url: '',
    }),

    mounted(): void {
      document.addEventListener('click', this.interceptClickHandler, true);
    },

    beforeDestroy(): void {
      document.removeEventListener('click', this.interceptClickHandler, true);
    },

    methods: {
      onCancel(): void {
        this.url = '';
        this.isOpen = false;
      },

      onContinue(): void {
        window.location.href = this.url;
      },

      interceptClickHandler(event: Event): void {
        const { hostname } = window.location;
        const link = (event?.target as HTMLElement).closest('a') as HTMLAnchorElement;
        if ((link && link.hostname && link.hostname !== hostname && link.getAttribute('download') === null) || (link && this.isExternalSite(link))) {
          event.preventDefault();
          this.url = link.href;
          this.isOpen = true;
        }
      },
    },
  });
</script>
